document.addEventListener("turbolinks:load", () => {
  const dropdownMenus = document.querySelectorAll(".js-dropdown");
  if (dropdownMenus.length < 1) {
    return false;
  }

  const dropdownSwitches: HTMLElement[] = Array.prototype.slice.call(
    document.querySelectorAll(".js-dropdown-switch"),
  );

  const openDropdownMenu = (dropdownSwitch: HTMLElement) => {
    const id = dropdownSwitch.getAttribute("id");
    const dropdownList = document.querySelector(`[aria-labelledby="${id}"]`);
    if (dropdownList) {
      dropdownSwitch.setAttribute("aria-expanded", "true");
      dropdownList.setAttribute("aria-hidden", "false");
    }
  };
  const closeDropdownMenu = (dropdownSwitch: HTMLElement) => {
    const id = dropdownSwitch.getAttribute("id");
    const dropdownList = document.querySelector(`[aria-labelledby="${id}"]`);
    if (dropdownList) {
      dropdownSwitch.setAttribute("aria-expanded", "false");
      dropdownList.setAttribute("aria-hidden", "true");
    }
  };

  dropdownSwitches.forEach((dropdownSwitch) => {
    dropdownSwitch.addEventListener("click", () => {
      const isExpanded = dropdownSwitch.ariaExpanded === "true";
      const active = document.querySelector('[aria-expanded="true"]');
      if (isExpanded || (active && active !== dropdownSwitch)) {
        closeDropdownMenu(dropdownSwitch);
      } else {
        openDropdownMenu(dropdownSwitch);
      }
    });
  });

  // ドロップダウン以外をクリックすると、開いた状態のドロップダウンメニューが閉じる挙動
  document.addEventListener("click", (event) => {
    const active = document.querySelector('[aria-expanded="true"]');
    const isClickedDropdown = event.target instanceof HTMLElement &&
      event.target.closest(".js-dropdown");
    if (!isClickedDropdown && active) {
      dropdownSwitches.forEach((dropdownSwitch) =>
        closeDropdownMenu(dropdownSwitch)
      );
    }
  });
});
