import QRCode from "qrcode";
import ClipboardJS from "clipboard";
import showingSnackBar from "./snackbar";

document.addEventListener("turbolinks:load", function () {
  const inviteUrl = document.getElementById("js-invite-url");
  const inviteQRCode = document.getElementById("js-invite-qrcode");

  if (!(inviteUrl && inviteUrl instanceof HTMLInputElement)) {
    return false;
  }

  QRCode.toString(
    inviteUrl.value,
    {
      type: "svg",
    },
    function (_err, svg) {
      inviteQRCode.innerHTML = svg;
    },
  );

  const inviteCopyButton = new ClipboardJS("#js-invite-copy-button");

  inviteCopyButton.on("success", function (event) {
    event.clearSelection();
    showingSnackBar("招待リンクをコピーしました");
  });
});
