export default function openClose(): void {
  Array.prototype.forEach.call(
    document.querySelectorAll(".js-click-open"),
    (element: HTMLElement) => {
      element.addEventListener("click", () => {
        openBy(element);
      });
      element.addEventListener("keydown", (event: KeyboardEvent) => {
        const eventKey = event.key;
        if (eventKey === "Enter") {
          openBy(element);
        }
      });

      if (element.classList.contains("js-open")) {
        openBy(element);
      }
    },
  );

  Array.prototype.forEach.call(
    document.querySelectorAll(".js-click-close"),
    (element: HTMLElement) => {
      element.addEventListener("click", () => {
        closeBy(element);
      });
      element.addEventListener("keydown", (event: KeyboardEvent) => {
        const eventKey = event.key;
        if (eventKey === "Enter") {
          openBy(element);
        }
      });

      if (element.classList.contains("js-close")) {
        closeBy(element);
      }
    },
  );

  Array.prototype.forEach.call(
    document.querySelectorAll(".js-check-open"),
    (element: HTMLElement) => {
      element.addEventListener("change", (event: Event) => {
        const eventTarget = event.target;
        if (eventTarget instanceof HTMLInputElement && eventTarget.checked) {
          openBy(element);
        }
      });

      if (element instanceof HTMLInputElement && element.checked) {
        openBy(element);
      }
    },
  );

  Array.prototype.forEach.call(
    document.querySelectorAll(".js-check-close"),
    (element: HTMLElement) => {
      element.addEventListener("change", (event: Event) => {
        const eventTarget = event.target;
        if (eventTarget instanceof HTMLInputElement && eventTarget.checked) {
          closeBy(element);
        }
      });

      if (element instanceof HTMLInputElement && element.checked) {
        closeBy(element);
      }
    },
  );

  Array.prototype.forEach.call(
    document.querySelectorAll(".js-toggle"),
    (element: HTMLElement) => {
      element.addEventListener("click", () => {
        toggleBy(element);
      });
      element.addEventListener("keydown", (event: KeyboardEvent) => {
        const eventKey = event.key;
        const isEnterKey = eventKey === "Enter" || event.keyCode === 13;
        if (isEnterKey) {
          event.preventDefault(); // Enter キーによるクリックを防止
          toggleBy(element);
        }
      });
    },
  );
}

function openBy(element: HTMLElement): void {
  const target = element.dataset.openTarget;
  if (target) {
    Array.prototype.forEach.call(
      document.querySelectorAll(target),
      (targetElement: HTMLElement) => {
        targetElement.classList.remove("--hidden");
        targetElement.classList.add("--visible");
        targetElement.hidden = false;
      },
    );
  }
}

function closeBy(element: HTMLElement): void {
  const target = element.dataset.closeTarget;
  if (target) {
    Array.prototype.forEach.call(
      document.querySelectorAll(target),
      (targetElement: HTMLElement) => {
        targetElement.classList.remove("--visible");
        targetElement.classList.add("--hidden");
        targetElement.hidden = true;
      },
    );
  }
}

function toggleBy(element: HTMLElement): void {
  const target = element.dataset.toggleTarget;
  const parent = element.dataset.toggleParent;
  const parentElement = document.querySelector(parent);
  const expanded = parentElement.getAttribute("aria-expanded");
  if (target && parent) {
    Array.prototype.forEach.call(
      document.querySelectorAll(target),
      (targetElement: HTMLElement) => {
        if (expanded == "false") {
          parentElement.setAttribute("aria-expanded", "true");
          targetElement.setAttribute("aria-hidden", "false");
          targetElement.hidden = false;
        } else {
          parentElement.setAttribute("aria-expanded", "false");
          targetElement.setAttribute("aria-hidden", "true");
          targetElement.hidden = true;
        }
      },
    );
  }
}
