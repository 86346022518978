import { CardRegistration, type Fincode } from "types/fincode.d";
import Turbolinks from "turbolinks";
import * as Sentry from "@sentry/browser";
import showingSnackBar from "packs/snackbar";

const fincodePublicKey =
  process.env.FINCODE_PUBLIC_API_KEY_FOR_PLATFORM_SUBSCRIPTION;
const fincodeSubscriptionFormId = "fincode-form";
const fincodeCustomerInputId = "fincode-customer-id";
const fincodeSubscriptionMountedId = "fincode";

document.addEventListener("turbolinks:load", async () => {
  const form = document.getElementById(fincodeSubscriptionFormId);
  const customerIdInput = document.getElementById(fincodeCustomerInputId);
  const submittedPath = document.getElementById("fincode-submitted-path");
  if (
    !(form && form instanceof HTMLFormElement &&
      customerIdInput && customerIdInput instanceof HTMLInputElement &&
      submittedPath && submittedPath instanceof HTMLInputElement)
  ) {
    return;
  }

  // @ts-ignore: Fincode がスクリプトタグ経由でしか利用できないため無視するしかない
  const fincode: Fincode = Fincode(fincodePublicKey);
  const appearance = {
    layout: "vertical",
    fontFamily: "'OpenSans', 'Noto Sans JP'",
    colorText: "393c41",
    colorLabelText: "393c41",
    colorBackgroundInput: "f7f8fa",
    colorError: "db4852",
  };
  const ui = fincode.ui(appearance);
  ui.create("cards", appearance);
  ui.mount(fincodeSubscriptionMountedId, "380");

  form.addEventListener("submit", async (event) => {
    event.preventDefault();
    const formData = await ui.getFormData();
    const card: CardRegistration = {
      customer_id: customerIdInput.value,
      default_flag: "1",
      card_no: formData.cardNo,
      expire: formData.expire,
      holder_name: formData.holderName,
      security_code: formData.CVC,
    };
    fincode.cards(card, async (status, response) => {
      if (status !== 200) {
        showingSnackBar(
          "カード情報の登録に失敗しました。入力情報をご確認ください。",
        );
        Sentry.captureException(response);
        return;
      }
      Turbolinks.visit(submittedPath.value);
    }, () => {
      showingSnackBar(
        "カード情報の登録に失敗しました。入力情報をご確認ください。",
      );
      Sentry.captureException(event);
    });
  });
});
