import showingSnackBar from "packs/snackbar";

document.addEventListener("turbolinks:load", () => {
  const textFields = document.querySelectorAll<HTMLInputElement>(
    ".js-half-width_numbers_only",
  );

  if (!textFields.length) return;

  textFields.forEach((textField) => {
    if (!(textField instanceof HTMLInputElement)) {
      return;
    }

    textField.addEventListener("input", (event: Event) => {
      const inputElement = event.target as HTMLInputElement;
      const inputNumber = inputElement.value.replace(
        /[０-９]/g,
        (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0),
      );

      if (/[^0-9]/.test(inputNumber)) {
        showingSnackBar("定員には数字を入力してください");
        inputElement.value = "";
        return;
      }

      inputElement.value = inputNumber;
    });
  });
});
