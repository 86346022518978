document.addEventListener("turbolinks:load", function () {
  const enrollLink: HTMLAnchorElement = document.querySelector(
    "#js-enroll-with-optional-bill",
  );

  if (!(enrollLink && enrollLink instanceof HTMLAnchorElement)) {
    return false;
  }

  const optionalBills: HTMLInputElement[] = Array.prototype.slice.call(
    document.querySelectorAll(".js-optional-bill"),
  );

  enrollLink.addEventListener("click", () => {
    const optionalBillId: string = optionalBills.find((optionalBill) =>
      optionalBill.checked
    )?.value;

    if (!optionalBillId) {
      return false;
    }

    enrollLink.href += `?optional_bill_id=${optionalBillId}`;
  });
});
