document.addEventListener("turbolinks:load", () => {
  const submitButtons = document.querySelectorAll<HTMLDivElement>(
    ".js-disable-on-submitting",
  );

  const setDisabled = (button) => {
    button.addEventListener("click", (event) => {
      event.preventDefault();

      const form = document.getElementById(button.dataset.targetForm);
      if (!(form instanceof HTMLFormElement)) {
        return;
      }

      button.disabled = true;
      form.submit();
    });
  };

  submitButtons.forEach((button) => setDisabled(button));
});
