import Turbolinks from "turbolinks";
import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/browser";

document.addEventListener("turbolinks:load", async () => {
  const form = document.getElementById("payment-form");
  const cardForm = document.getElementById("stripe-card-form");
  const usingNewCardElement = document.getElementById("using-new-card");
  const clientSecretElement = document.getElementById("client-secret");
  const savingCardElement = document.getElementById("stripe-card-saving");
  const paymentMethods: HTMLInputElement[] = Array.prototype.slice.call(
    document.querySelectorAll(".js-payment-method"),
  );

  if (
    !(form && form instanceof HTMLFormElement &&
      cardForm &&
      usingNewCardElement && usingNewCardElement instanceof HTMLInputElement &&
      clientSecretElement && clientSecretElement instanceof HTMLInputElement &&
      savingCardElement && savingCardElement instanceof HTMLInputElement &&
      document.getElementById("stripe-card-number"))
  ) {
    return;
  }

  const { stripe, card } = await createCardElements();

  // 保存済みクレジットカードがある場合、カードフォームを非表示
  if (paymentMethods.length > 0) {
    cardForm.hidden = true;
  }

  paymentMethods.forEach((paymentMethod) => {
    paymentMethod.addEventListener("change", () => {
      if (usingNewCardElement.checked) {
        usingNewCardElement.checked = false;
        cardForm.hidden = true;
      }
    });
  });

  usingNewCardElement.addEventListener("change", (event) => {
    event.preventDefault();

    if (usingNewCardElement.checked) {
      // 新しいカードを登録する際には保存済みカードの選択を解除する
      paymentMethods.forEach((method) => method.checked = false);
      cardForm.hidden = false;
    } else {
      cardForm.hidden = true;
    }
  });

  form.addEventListener("submit", async (event) => {
    event.preventDefault();

    const paymentMethod = usingNewCardElement.checked
      ? { card }
      : paymentMethods.find((method) => method.checked)?.value;

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecretElement.value,
        {
          payment_method: paymentMethod,
          payment_method_options: {
            card: {
              setup_future_usage: savingCardElement.checked
                ? "off_session"
                : null,
            },
          },
        },
      );
      if (error) {
        Sentry.captureMessage(error.message);
        alert(error.message);
        return;
      }
      if (paymentIntent.status === "succeeded") {
        const formData = new FormData(form);
        formData.append(
          "payment[payment_intent_id]",
          paymentIntent.id,
        );
        const response = await fetch(form.action, {
          method: "POST",
          body: formData,
        });
        const redirectURL = response.url;
        Turbolinks.visit(redirectURL);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  });
});

const createCardElements = async () => {
  const publicKey = process.env.STRIPE_PUBLIC_API_KEY;
  const stripe = await loadStripe(publicKey);
  const elements = stripe.elements({
    fonts: [
      {
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400&family=Roboto+Mono:wght@400&display=swap",
      },
    ],
  });

  const stripeCardElementOptions = {
    style: {
      base: {
        color: "#393c41",
        fontFamily: "'Roboto Mono', 'Noto Sans JP', sans-serif",
        fontSize: "16px",

        "::placeholder": {
          color: "#767b85",
        },
      },
      invalid: {
        color: "#cc1f24",
      },
    },
    classes: {
      focus: "focused",
      empty: "empty",
      invalid: "invalid",
    },
  };

  const errorDisplay = document.getElementById("stripe-card-errors");
  const showError = ({ error }) => {
    if (!errorDisplay) return;
    if (error) {
      errorDisplay.textContent = error.message;
    } else {
      errorDisplay.textContent = "";
    }
  };

  const card = elements.create("cardNumber", stripeCardElementOptions);
  card.mount("#stripe-card-number");
  card.on("change", showError);

  const cardExpiry = elements.create("cardExpiry", stripeCardElementOptions);
  cardExpiry.mount("#stripe-card-expiry");
  cardExpiry.on("change", showError);

  const cardCvc = elements.create("cardCvc", stripeCardElementOptions);
  cardCvc.mount("#stripe-card-cvc");
  cardCvc.on("change", showError);

  return { stripe, card };
};
