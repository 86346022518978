import { validateBillAmount, validateBillName } from "../form-validation";

document.addEventListener("turbolinks:load", function () {
  const nameInput: HTMLInputElement = document.querySelector(
    "#js-recurrence-bill-name",
  );
  const nameValidationMessage: HTMLDivElement = document.querySelector(
    "#js-recurrence-bill-name-validation-message",
  );
  const amountInput: HTMLInputElement = document.querySelector(
    "#js-recurrence-bill-amount",
  );
  const amountValidationMessage: HTMLDivElement = document.querySelector(
    "#js-recurrence-bill-amount-validation-message",
  );

  if (
    !(
      nameInput && nameInput instanceof HTMLInputElement &&
      nameValidationMessage &&
      nameValidationMessage instanceof HTMLDivElement &&
      amountInput && amountInput instanceof HTMLInputElement &&
      amountValidationMessage &&
      amountValidationMessage instanceof HTMLDivElement
    )
  ) {
    return false;
  }

  nameInput.addEventListener("change", (event) => {
    const { currentTarget } = event;
    if (!(currentTarget instanceof HTMLInputElement)) return;
    nameValidationMessage.textContent = validateBillName(currentTarget.value);
  });

  amountInput.addEventListener("change", (event) => {
    const { currentTarget } = event;
    if (!(currentTarget instanceof HTMLInputElement)) return;
    amountValidationMessage.textContent = validateBillAmount(
      currentTarget.value,
    );
  });
});
