import { add, differenceInMinutes, format, parse } from "date-fns";

document.addEventListener("turbolinks:load", function () {
  const eventStartDateInput: HTMLInputElement = document.querySelector(
    "#js-event-start-date",
  );
  const eventEndDateInput: HTMLInputElement = document.querySelector(
    "#js-event-end-date",
  );
  const eventStartTimeInput: HTMLInputElement = document.querySelector(
    "#js-event-start-time",
  );
  const eventEndTimeInput: HTMLInputElement = document.querySelector(
    "#js-event-end-time",
  );

  if (
    !(eventStartDateInput && eventStartDateInput instanceof HTMLInputElement &&
      eventEndDateInput && eventEndDateInput instanceof HTMLInputElement &&
      eventStartTimeInput && eventStartTimeInput instanceof HTMLInputElement &&
      eventEndTimeInput && eventEndTimeInput instanceof HTMLInputElement)
  ) {
    return false;
  }

  const convertDate = (date: string, time: string): Date =>
    parse(`${date} ${time}`, "yyyy-MM-dd HH:mm", new Date());

  const eventStartDate = () =>
    convertDate(eventStartDateInput.value, eventStartTimeInput.value);

  const eventEndDate = () =>
    convertDate(eventEndDateInput.value, eventEndTimeInput.value);

  const calcEventDuration = (): number =>
    differenceInMinutes(eventEndDate(), eventStartDate());

  let eventDuration = calcEventDuration();
  const updateEventDuration = () => {
    try {
      eventDuration = calcEventDuration();
    } catch {
      // ユーザーの入力がおかしいだけなので何もしない
    }
  };

  const updateEventEndDate = () => {
    try {
      const updatedEventEndDate = add(eventStartDate(), {
        minutes: eventDuration,
      });
      eventEndDateInput.value = format(updatedEventEndDate, "yyyy-MM-dd");
      eventEndTimeInput.value = format(updatedEventEndDate, "HH:mm");
    } catch {
      // ユーザーの入力がおかしいだけなので何もしない
    }
  };

  eventStartDateInput.addEventListener("change", updateEventEndDate);
  eventStartTimeInput.addEventListener("change", updateEventEndDate);
  eventEndDateInput.addEventListener("change", updateEventDuration);
  eventEndTimeInput.addEventListener("change", updateEventDuration);
});
