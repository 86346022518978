import { loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/browser";

document.addEventListener("turbolinks:load", async () => {
  const form = document.getElementById("konbini_payment-form");
  const clientSecretElement = document.getElementById("client-secret");
  const nameElement = document.getElementById("name");
  const emailElement = document.getElementById("email");
  const confirmationNumberElement = document.getElementById(
    "confirmation-number",
  );

  if (
    !(form && form instanceof HTMLFormElement &&
      clientSecretElement && clientSecretElement instanceof HTMLInputElement &&
      nameElement && nameElement instanceof HTMLInputElement &&
      emailElement && emailElement instanceof HTMLInputElement &&
      confirmationNumberElement &&
      confirmationNumberElement instanceof HTMLInputElement)
  ) {
    return;
  }

  const publicKey = process.env.STRIPE_PUBLIC_API_KEY;
  const stripe = await loadStripe(publicKey);

  form.addEventListener("submit", async (event) => {
    event.preventDefault();

    try {
      const { error, _paymentIntent } = await stripe.confirmKonbiniPayment(
        clientSecretElement.value,
        {
          payment_method: {
            billing_details: {
              name: nameElement.value,
              email: emailElement.value,
            },
          },
          payment_method_options: {
            konbini: {
              confirmation_number: confirmationNumberElement.value,
            },
          },
        },
      );
      if (error) {
        Sentry.captureMessage(error.message);
        alert(error.message);
        return;
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  });
});
