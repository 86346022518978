document.addEventListener("turbolinks:load", () => {
  const issueButtons = document.querySelectorAll<HTMLDivElement>(
    ".js-submit-to-issue",
  );
  const draftButtons = document.querySelectorAll<HTMLDivElement>(
    ".js-draft-submit",
  );

  const setDraftAndSubmit = (button, isDraft: boolean) => {
    button.addEventListener("click", (event) => {
      event.preventDefault();

      const form = document.getElementById(button.dataset.targetForm);
      const hiddenField = document.getElementById(
        button.dataset.targetHiddenField,
      );
      if (
        !(form instanceof HTMLFormElement &&
          hiddenField instanceof HTMLInputElement)
      ) {
        return;
      }

      hiddenField.value = isDraft.toString();
      button.disabled = true;
      form.submit();
    });
  };

  issueButtons.forEach((button) => setDraftAndSubmit(button, false));
  draftButtons.forEach((button) => setDraftAndSubmit(button, true));
});
