document.addEventListener("turbolinks:load", function () {
  const transparentHeader = document.getElementById("js-transparent-header");

  if (!transparentHeader) {
    return false;
  }

  const activeClassName = "--active";
  const throttle = function (fn, interval) {
    let lastTime = Date.now() - interval;
    return function () {
      if (lastTime + interval < Date.now()) {
        lastTime = Date.now();
        fn();
      }
    };
  };

  self.addEventListener(
    "scroll",
    throttle(function () {
      scrollY = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollY > 100) {
        if (!transparentHeader.classList.contains(activeClassName)) {
          transparentHeader.classList.add(activeClassName);
        }
      } else {
        if (transparentHeader.classList.contains(activeClassName)) {
          transparentHeader.classList.remove(activeClassName);
        }
      }
    }, 100),
  );
});
