document.addEventListener("turbolinks:load", function () {
  const switches = document.querySelectorAll(".js-drawer-switch");
  const links = document.querySelectorAll(".js-drawer-link a");

  for (let i = 0; i < switches.length; i++) {
    switches[i].addEventListener("click", function () {
      document.documentElement.classList.toggle("is-drawer-opened");
    });
  }

  for (let i = 0; i < links.length; i++) {
    links[i].addEventListener("click", function () {
      document.documentElement.classList.remove("is-drawer-opened");
    });
  }
});
