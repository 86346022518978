document.addEventListener("turbolinks:load", () => {
  const selectAllBoxes: HTMLInputElement[] = Array.prototype.slice.call(
    document.querySelectorAll(".js-course-members-select-all"),
  );
  const targetBoxes: HTMLInputElement[] = Array.prototype.slice.call(
    document.querySelectorAll(".js-course-members-target"),
  );
  const changeState = "--change";
  const state = {
    enrollments: targetBoxes.map((element) => ({
      id: element.id,
      checked: element.checked,
    })),
  };

  function setState(): void {
    state.enrollments.forEach((enrollment, i) => {
      targetBoxes[i].checked = enrollment.checked;
    });
    if (state.enrollments.every((enrollment) => enrollment.checked)) {
      selectAllBoxes.forEach((selectAllBox) => {
        selectAllBox.checked = true;
      });
    } else {
      selectAllBoxes.forEach((selectAllBox) => {
        selectAllBox.checked = false;
      });
    }
    printCheckedCount();
  }
  setState();

  function printCheckedCount() {
    const header = document.getElementById("js-course-members-count-header");
    const count = state.enrollments
      .filter((enrollment) => enrollment.checked).length;
    if (!header) {
      return;
    }
    header.textContent = `（${count}）`;
  }
  printCheckedCount();

  selectAllBoxes.forEach((selectAllBox) => {
    selectAllBox.addEventListener("change", () => {
      state.enrollments = state.enrollments.map((enrollment) => {
        if (selectAllBox.checked != enrollment.checked) {
          const item = document.getElementById(enrollment.id)
            .closest(".js-course-members-item");
          item.classList.toggle(changeState);
        }
        return { ...enrollment, checked: selectAllBox.checked };
      });
      setState();
    });
  });

  targetBoxes.forEach((targetBox) => {
    targetBox.addEventListener("change", () => {
      state.enrollments = state.enrollments.map((enrollment) => {
        return {
          ...enrollment,
          checked: enrollment.id == targetBox.id
            ? targetBox.checked
            : enrollment.checked,
        };
      });
      const item = targetBox.closest(".js-course-members-item");
      item.classList.toggle(changeState);
      setState();
    });
    const memberItem = targetBox.closest(".js-course-members-item");
    memberItem.classList.add(
      targetBox.checked ? "--pre-selected" : "--un-selected",
    );
  });
});
