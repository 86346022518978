import {
  validateAnnouncementAddressee,
  validateAnnouncementBody,
  validateAnnouncementTitle,
} from "../form-validation";
import multiFileAttachment from "../multi-file-attachment";

document.addEventListener("turbolinks:load", function () {
  const titleInput: HTMLInputElement = document.querySelector(
    "#js-announcement-title",
  );
  const titleValidationMessage: HTMLDivElement = document.querySelector(
    "#js-announcement-title-validation-message",
  );
  const bodyTextArea: HTMLTextAreaElement = document.querySelector(
    "#js-announcement-body",
  );
  const bodyValidationMessage: HTMLDivElement = document.querySelector(
    "#js-announcement-body-validation-message",
  );
  const sharedWithClassroom: HTMLInputElement = document.querySelector(
    "#announcement_shared_with_classroom",
  );
  const addresseeSelect: HTMLDivElement = document.querySelector(
    "#js-radio-content-addressee",
  );
  const addresseeValidationMessage: HTMLDivElement = document.querySelector(
    "#js-announcement-addressee-validation-message",
  );

  if (
    !(
      titleInput && titleInput instanceof HTMLInputElement &&
      titleValidationMessage &&
      titleValidationMessage instanceof HTMLDivElement &&
      bodyTextArea && bodyTextArea instanceof HTMLTextAreaElement &&
      bodyValidationMessage &&
      bodyValidationMessage instanceof HTMLDivElement &&
      sharedWithClassroom && sharedWithClassroom instanceof HTMLInputElement &&
      addresseeSelect && addresseeSelect instanceof HTMLDivElement &&
      addresseeValidationMessage &&
      addresseeValidationMessage instanceof HTMLDivElement
    )
  ) {
    return false;
  }

  titleInput.addEventListener("blur", (event) => {
    const { currentTarget } = event;
    if (!(currentTarget instanceof HTMLInputElement)) return;
    titleValidationMessage.textContent = validateAnnouncementTitle(
      currentTarget.value,
    );
  });

  bodyTextArea.addEventListener("blur", (event) => {
    const { currentTarget } = event;
    if (!(currentTarget instanceof HTMLTextAreaElement)) return;
    bodyValidationMessage.textContent = validateAnnouncementBody(
      currentTarget.value,
    );
  });

  sharedWithClassroom.addEventListener("click", (event) => {
    const { currentTarget } = event;
    if (!(currentTarget instanceof HTMLInputElement)) return;
    addresseeValidationMessage.textContent = validateAnnouncementAddressee(
      currentTarget.value,
    );
  });

  addresseeSelect.addEventListener("click", (event) => {
    const { currentTarget } = event;
    if (!(currentTarget instanceof HTMLDivElement)) return;
    addresseeValidationMessage.textContent = validateAnnouncementAddressee(
      currentTarget.innerText,
    );
  });

  const validateBeforeDraftSubmit = (event: MouseEvent) => {
    event.preventDefault();

    const titleValidationResultMessage = validateAnnouncementTitle(
      titleInput.value,
    );
    if (titleValidationResultMessage.length > 0) {
      titleValidationMessage.textContent = titleValidationResultMessage;
      event.stopImmediatePropagation();
      return;
    }

    const bodyValidationResultMessage = validateAnnouncementBody(
      bodyTextArea.value,
    );
    if (bodyValidationResultMessage.length > 0) {
      bodyValidationMessage.textContent = bodyValidationResultMessage;
      event.stopImmediatePropagation();
      return;
    }
  };

  const draftButton: HTMLButtonElement = document.querySelector(
    ".js-draft-submit",
  );

  if (draftButton && draftButton instanceof HTMLButtonElement) {
    draftButton.addEventListener(
      "click",
      (event) => validateBeforeDraftSubmit(event),
      true, // useCapture=trueにして他のclickイベントハンドラより先に実行する
    );
  }

  multiFileAttachment();
});
