import toBase64 from "./file-to-base64";

export default function filePreview() {
  const elements = document.querySelectorAll(".js-file-preview");

  Array.prototype.forEach.call(elements, (element: HTMLInputElement) => {
    element.addEventListener("change", async (event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files[0];
      if (!file || !file.type.match("image.*")) {
        return;
      }

      const data = await toBase64(file);
      const selector = element.dataset.previewTarget;

      if (typeof data === "string" && typeof selector === "string") {
        const targetElements = document.querySelectorAll(selector);
        Array.prototype.forEach.call(
          targetElements,
          (element: HTMLElement) => {
            if (element instanceof HTMLImageElement) {
              element.src = data;
            }
          },
        );
      }
    });
  });
}
