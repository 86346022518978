document.addEventListener("turbolinks:load", function () {
  const historyBackButtons = document.querySelectorAll(".js-history-back");

  for (let i = 0; i < historyBackButtons.length; i++) {
    historyBackButtons[i].addEventListener("click", function (event) {
      history.back();
      event.preventDefault();
    });
  }
});
