const isIntegralValue = (value: string): boolean => {
  if (!value) return false;
  return /^[-]?([1-9]\d*|0)$/.test(value);
};

export const validateBillName = (value: string): string => {
  if (!value) {
    return "項目名を入力してください。";
  }

  if (value.length > 140) {
    return "項目名は140文字以内で入力してください。";
  }

  return "";
};

export const validateBillAmount = (value: string): string => {
  if (!value) {
    return "金額を入力してください。";
  }

  if (!isIntegralValue(value)) {
    return "金額は整数で入力してください。";
  }

  const amount = Number.parseInt(value);
  if (amount < 300) {
    return "金額は300円以上で入力してください。";
  }

  if (amount > 300_000) {
    return "金額は30万円以下で入力してください。";
  }

  return "";
};

export const validateOptionalBillAmount = (value: string): string => {
  if (!value) {
    return "金額を入力してください。";
  }

  if (!isIntegralValue(value)) {
    return "金額は整数で入力してください。";
  }

  const amount = Number.parseInt(value);
  if (amount != 0 && amount < 300) {
    return "金額は0円、または300円以上で入力してください。";
  }

  if (amount > 300_000) {
    return "金額は30万円以下で入力してください。";
  }

  return "";
};

export const validateAnnouncementTitle = (value: string): string => {
  if (!value) {
    return "タイトルを入力してください。";
  }

  if (value.length > 140) {
    return "タイトルは140文字以内で入力してください。";
  }

  return "";
};

export const validateAnnouncementBody = (value: string): string => {
  if (!value) {
    return "内容を入力してください。";
  }

  if (value.length > 2000) {
    return "内容は2000文字以内で入力してください。";
  }

  return "";
};

export const validateAnnouncementAddressee = (value: string): string => {
  if (value == "0人") {
    return "受講者を選択してください。";
  }

  return "";
};
