document.addEventListener("turbolinks:load", () => {
  const newPaymentButton = document.getElementsByClassName(
    "js-new-payment-button",
  );
  if (!(newPaymentButton && newPaymentButton instanceof HTMLButtonElement)) {
    return;
  }

  newPaymentButton.addEventListener("click", () => {
    newPaymentButton.disabled = true;
    const newPaymentForm = newPaymentButton.closest("form");
    newPaymentForm.submit();
  });
});
