document.addEventListener("turbolinks:load", function () {
  const dialogToggleSwitches = document.querySelectorAll(
    ".js-dialog-v2-toggle-switch",
  );

  if (dialogToggleSwitches.length < 1) {
    return false;
  }

  for (let i = 0; i < dialogToggleSwitches.length; i++) {
    dialogToggleSwitches[i].addEventListener("click", function () {
      const dialog = document.getElementById(this.dataset.targetDialog);
      const visibleSelector = "--visible";

      if (dialog.classList.contains(visibleSelector)) {
        dialog.classList.remove(visibleSelector);
      } else {
        dialog.classList.add(visibleSelector);
      }
    });
  }
});
