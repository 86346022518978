document.addEventListener("turbolinks:load", function () {
  const radioButtons: NodeListOf<HTMLInputElement> = document.querySelectorAll(
    ".js-classroom-manager-role-dialog",
  );
  const button: HTMLButtonElement | null = document.querySelector(
    ".js-role-button",
  );
  const dialog: HTMLElement | null = document.querySelector(
    "#js-owner-change-confirm-dialog",
  );
  const visibleSelector: string = "--visible";

  if (!button || !dialog) return;

  button.addEventListener("click", function () {
    const selectedRole: string | undefined = Array.from(radioButtons).find((
      radio,
    ) => radio.checked)?.value;

    if (selectedRole === "admin") {
      dialog.classList.add(visibleSelector);
    } else {
      button.setAttribute("type", "submit");
      dialog.classList.remove(visibleSelector);
    }
  });
});
