import showingSnackBar from "../snackbar";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import { captureException } from "@sentry/browser";

document.addEventListener("turbolinks:load", async () => {
  const usingNewCardElement = document.getElementById("using-new-card");
  const newCardElement = document.getElementById("new-card-form-section");
  const paymentForm = document.getElementById("stripe-payment-form");

  if (
    !(
      usingNewCardElement && usingNewCardElement instanceof HTMLInputElement &&
      paymentForm && paymentForm instanceof HTMLFormElement
    )
  ) {
    return;
  }

  const stripe = await loadStripe(process.env.STRIPE_PUBLIC_API_KEY);
  let elements: StripeElements;

  // view側で、登録済みカードがない時にcheckedにしておくことでフォームを表示させる
  if (usingNewCardElement.checked) {
    newCardElement.hidden = false;
    elements = await createPaymentElement(stripe);
  }

  // カード登録後の処理。URLパラメーターで notice が渡ってきていたら表示する
  const params = new URLSearchParams(document.location.search.substring(1));
  if (params.get("setup_intent_client_secret")) {
    await notifySetupIntentStatus(
      stripe,
      params.get("setup_intent_client_secret"),
    );
  }

  usingNewCardElement.addEventListener("change", async (event) => {
    event.preventDefault();
    if (usingNewCardElement.checked) {
      newCardElement.hidden = false;
      elements = await createPaymentElement(stripe);
    } else {
      newCardElement.hidden = true;
    }
  });

  paymentForm.addEventListener("submit", async (event) => {
    event.preventDefault();
    // ref: https://stripe.com/docs/js/payment_methods/create_payment_method
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: paymentForm.action,
        payment_method_data: {
          billing_details: {
            address: {
              country: "JP",
            },
          },
        },
      },
    });

    if (error) {
      const messageContainer = document.querySelector("#stripe-error-message");
      messageContainer.textContent = error.message;
      captureException(error);
    }
  });
});

const createPaymentElement = async (stripe: Stripe) => {
  const clientSecret = await fetchClientSecret();
  const elements = stripe.elements({
    clientSecret,
    appearance: {
      variables: {
        colorPrimary: "#1f7acc",
        colorText: "#393c41",
        colorDanger: "#cc1f24",
        fontFamily: "'Roboto Mono', 'Noto Sans JP', sans-serif",
        spacingUnit: "4px",
        borderRadius: "4px",
      },
    },
  });

  const paymentElement = elements.create("payment", {
    fields: {
      billingDetails: {
        address: {
          country: "never",
        },
      },
    },
  });
  paymentElement.mount("#stripe-payment-element");

  return elements;
};

const fetchClientSecret = async () => {
  const clientSecretPath =
    document.getElementById("stripe-payment-form").dataset.clientSecretPath;

  const response = await fetch(clientSecretPath);
  const responseJson = await response.json();
  return responseJson.client_secret;
};

const notifySetupIntentStatus = async (
  stripe: Stripe,
  setup_intent_client_secret: string,
) => {
  const { setupIntent } = await stripe.retrieveSetupIntent(
    setup_intent_client_secret,
  );
  switch (setupIntent.status) {
    case "succeeded": {
      showingSnackBar("カードを登録しました");
      break;
    }
    case "processing": {
      showingSnackBar("決済情報を処理中です。完了しましたら更新します");
      break;
    }
    case "requires_payment_method": {
      showingSnackBar(
        "決済情報の処理に失敗しました。別の支払い方法をお試しください",
      );
      break;
    }
  }
};
