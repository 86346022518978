// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import "channels";
import "./course-members-select";
import "./dialog-v2";
import "./dialog";
import "./draft-submit";
import "./disable-on-submitting";
import "./drawer";
import "./dropdown";
import "./classrooms/classroom_managers/classroom-manager-role-dialog";
import filePreview from "./file-preview";
import "./header";
import "./history-back";
import "./invite";
import openClose from "./open-close";
import "./snackbar";
import "./styles/index.scss";
import "./bills/show";
import "./bills/card_payments/index";
import "./bills/konbini_payments/index";
import "./stripe/payment-method-form";
import "./fincode/platform-subscription-form";
import "./classrooms/enrollment";
import "./sessions/new";
import "./events/index";
import "./bill_group/new";
import "./recurrence_bill/new";
import "./optional_bill/new";
import "./settings/bank_accounts/new";
import "./announcements/new";
import "./validate_input_number";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://f089295be7a8450f964ce0c168bd2eb6@o1008895.ingest.sentry.io/5990755",
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener("turbolinks:load", () => {
  filePreview();
  openClose();
});
