document.addEventListener("turbolinks:load", () => {
  const bankCodeTargets: NodeListOf<HTMLInputElement> = document
    .querySelectorAll(
      ".js-bank-code-target",
    );
  const branchCodeInput: HTMLInputElement = document.querySelector(
    "#js-bank-account-branch-code",
  );
  const bankAccountNumberInput: HTMLInputElement = document.querySelector(
    "#js-bank-account-number",
  );
  const bankAccountNameInput: HTMLInputElement = document.querySelector(
    "#js-bank-account-name",
  );
  const bankAccountTypeInputs: NodeListOf<HTMLInputElement> = document
    .querySelectorAll(
      ".js-bank-account-type",
    );
  const branchCodeElement: HTMLInputElement = document.querySelector(
    ".js-bank-account-branch-code-item",
  );
  const bankAccountNumberElement: HTMLInputElement = document.querySelector(
    ".js-bank-account-number-item",
  );
  const bankAccountNameElement: HTMLInputElement = document.querySelector(
    ".js-bank-account-name-item",
  );
  const bankAccountTypeElement: HTMLInputElement = document.querySelector(
    ".js-bank-account-type-item",
  );
  const bankInitialKanaNavs: NodeListOf<HTMLElement> = document
    .querySelectorAll(
      ".js-bank-initial-kana-nav",
    );
  const selectBankLabels: NodeListOf<HTMLElement> = document
    .querySelectorAll(
      ".js-select-bank-label",
    );
  if (
    !(
      bankCodeTargets && bankCodeTargets[0] instanceof HTMLInputElement &&
      branchCodeElement && branchCodeInput &&
      branchCodeInput instanceof HTMLInputElement &&
      bankAccountNumberElement && bankAccountNumberInput &&
      bankAccountNumberInput instanceof HTMLInputElement &&
      bankAccountNameElement && bankAccountNameInput &&
      bankAccountNameInput instanceof HTMLInputElement &&
      bankAccountTypeElement && bankAccountTypeInputs[0] &&
      bankAccountTypeInputs[0] instanceof HTMLInputElement &&
      selectBankLabels.length > 0
    )
  ) {
    return false;
  }

  const refreshForm = () => {
    const selectedBank = Array.from(bankCodeTargets).find((t) => t.checked);
    if (!(selectedBank && selectedBank instanceof HTMLInputElement)) return;

    const {
      requiredBranchCode,
      requiredAccountNumber,
      requiredAccountName,
      requiredAccountType,
      bankLabel,
    } = selectedBank.dataset;
    if (
      !(requiredBranchCode &&
        requiredAccountType &&
        requiredAccountNumber &&
        requiredAccountName &&
        bankLabel)
    ) {
      return false;
    }

    if (requiredBranchCode === "false") {
      branchCodeInput.value = "";
      branchCodeElement.hidden = true;
    } else {
      branchCodeElement.hidden = false;
    }
    if (requiredAccountNumber === "false") {
      bankAccountNumberInput.value = "";
      bankAccountNumberElement.hidden = true;
    } else {
      bankAccountNumberElement.hidden = false;
    }
    if (requiredAccountName === "false") {
      bankAccountNameInput.value = "";
      bankAccountNameElement.hidden = true;
    } else {
      bankAccountNameElement.hidden = false;
    }
    if (requiredAccountType === "false") {
      bankAccountTypeElement.value = "";
      bankAccountTypeElement.hidden = true;
    } else {
      bankAccountTypeElement.hidden = false;
    }

    selectBankLabels.forEach((label) => {
      label.textContent = bankLabel;
    });
  };

  // ブラウザバック時も動いてほしいので pageshow を見ている
  globalThis.addEventListener("pageshow", refreshForm);
  bankCodeTargets.forEach(
    (target) => target.addEventListener("change", refreshForm),
  );

  bankInitialKanaNavs.forEach((kanaNav) => {
    kanaNav.addEventListener("click", () => {
      const bankGroup = document.getElementById(kanaNav.dataset.initialKanaId);
      // position:stickyになっていると上にスクロールできないためrelativeにする
      bankGroup.classList.add("--relative");
      bankGroup.scrollIntoView(
        { behavior: "smooth" },
      );
      // position:stickyにもどす
      bankGroup.classList.remove("--relative");
    });
  });
});
