import toBase64 from "./file-to-base64";

type Image = `image/${string}`;
type Pdf = "application/pdf";

type PreviewFile = {
  name: string;
  type: Image | Pdf;
  content: string | ArrayBuffer;
};

function createImagePreviewElement(previewFile: PreviewFile): HTMLElement {
  const wrapperDivElem = document.createElement("div");

  const innerDivElem = document.createElement("div");
  innerDivElem.className = "thumbnail-card__media";

  const imageElem = new Image(); // プレビュー画像のサイズはCSSで設定する
  imageElem.src = previewFile.content as string;

  innerDivElem.appendChild(imageElem);
  wrapperDivElem.appendChild(innerDivElem);

  return wrapperDivElem;
}

function createPdfPreviewElement(previewFile: PreviewFile): HTMLElement {
  const wrapperDivElem = document.createElement("div");

  const innerDivFileElem = document.createElement("div");
  innerDivFileElem.className = "thumbnail-card__file";

  const divBody = document.createElement("div");
  divBody.className = "_body";
  divBody.innerText = previewFile.name;

  const divTrailing =
    '<div class="_trailing"><span class="icon" data-icon="image"></span>PDF</div>';
  innerDivFileElem.innerHTML = divTrailing;
  innerDivFileElem.prepend(divBody);
  wrapperDivElem.appendChild(innerDivFileElem);

  return wrapperDivElem;
}

function createPreviewElement(previewFile: PreviewFile): HTMLElement {
  const liElem = document.createElement("li");
  liElem.className = "_item";

  const outerDivElem = document.createElement("div");
  outerDivElem.className = "thumbnail-card";

  const wrapperDivElem = previewFile.type.match("image.*")
    ? createImagePreviewElement(previewFile)
    : createPdfPreviewElement(previewFile);

  outerDivElem.appendChild(wrapperDivElem);
  liElem.appendChild(outerDivElem);

  return liElem;
}

async function multiFilePreview(target: HTMLInputElement) {
  const files = target.files;
  if (!files || !files.length) return;

  const selector = target.dataset.previewTarget;
  if (!selector || typeof selector !== "string") return;

  const targetElement = document.querySelector(selector);
  if (!targetElement) return;

  const validFiles = Array.from(files)
    .filter((file) => file && file.type.match("image.*|application/pdf"));

  const previewElements = (await Promise.all(
    validFiles.map(async (file) => ({
      name: file.name,
      type: file.type,
      content: await toBase64(file),
    })),
  )).filter((file: PreviewFile) => typeof file.content === "string")
    .map((file: PreviewFile) => createPreviewElement(file));

  targetElement.textContent = ""; // 前回のプレビューをクリア
  targetElement.append(...previewElements);
}

function validateNumberOfFiles(files: FileList): boolean {
  return files.length <= 5;
}

function validateSizeOfFiles(files: FileList): boolean {
  const largeFiles = Array.from(files).map((file) => file.size / 1024 ** 2)
    .filter((mbSize) => mbSize > 10);

  return largeFiles.length == 0;
}

function clearFileAttachments(target: HTMLInputElement) {
  target.value = "";
}

export default function multiFileAttachment() {
  const elements = document.querySelectorAll(".js-multi-file-preview");

  Array.prototype.forEach.call(elements, (element: HTMLInputElement) => {
    element.addEventListener("change", async (event) => {
      const target = event.target as HTMLInputElement;
      const multiple = element.multiple;
      if (!multiple) return;

      const files = target.files;
      if (!files || !files.length) return;

      if (!validateNumberOfFiles(files)) {
        alert("添付できるファイルは5つまでです。");
        clearFileAttachments(target);
        return;
      }

      if (!validateSizeOfFiles(files)) {
        alert("添付するファイルのサイズは10MB以下にしてください。");
        clearFileAttachments(target);
        return;
      }

      await multiFilePreview(target);
    });
  });
}
