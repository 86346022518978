document.addEventListener("turbolinks:load", function () {
  const openSwitches = document.querySelectorAll(".js-dialog-open");
  const closeSwitches = document.querySelectorAll(".js-dialog-close");
  const openOnceDialogs = document.querySelectorAll(".js-dialog-open-once");

  for (let i = 0; i < openSwitches.length; i++) {
    openSwitches[i].addEventListener("click", function () {
      document.getElementById(this.dataset.dialog).classList.add("--visible");
    });
  }

  for (let i = 0; i < closeSwitches.length; i++) {
    closeSwitches[i].addEventListener("click", function () {
      const dialog = this.closest(".js-dialog");
      dialog.classList.remove("--visible");

      if (dialog.classList.contains("js-dialog-open-once")) {
        localStorage.setItem(dialog.dataset.dialogKey, true);
      }
    });
  }

  for (let i = 0; i < openOnceDialogs.length; i++) {
    const isOpened = localStorage.getItem(openOnceDialogs[i].dataset.dialogKey);

    if (isOpened) {
      openOnceDialogs[i].classList.remove("--visible");
    }
  }
});
